import React from "react";
import { Container } from "reactstrap";

export default () => {

    return (
        <>
            <Container>
                <div id="intro" style={{
					textAlign: "center"
				}}>
                    <h1>
						AM Consortium Investment
					</h1>
					<h2>
						Consolidation & Development
					</h2>
                    <p>
						Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Maecenas faucibus mollis interdum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
					</p>
					<p>
						Maecenas faucibus mollis interdum. Sed posuere consectetur est at lobortis. Donec ullamcorper nulla non metus auctor fringilla.
                    </p>
                </div>
            </Container>
        </>
    )
}